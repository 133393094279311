import React, { useState } from "react";
import classes from "../AccountModal.module.css";
import { AccessManagementUserBase } from "types/auth";
import cx from "classnames"
import Button from "components/_Library/Button/Button";
import { useSetState } from "@mantine/hooks";
import { useUpdateUserDetailsMutation } from "crud/hooks/users";
import { useUserMeQuery } from "crud/hooks/me";
import { Select, TextInput } from "@mantine/core";
import { NotificationRegions } from "store/system/systemTypes";
import ControlSection from "components/_Library/ControlSection/ControlSection";
import { INDUSTRIES, LINES_OF_BUSINESS, ROLE_DIVISIONS } from "store/user/utils";
import { useDispatch } from "react-redux";
import { setAlert } from "store/system/systemActions";

interface OwnProps {
    user: AccessManagementUserBase | undefined
}

type DetailsTabProps = OwnProps;

const DetailsTab: React.FC<DetailsTabProps> = ({ user }) => {

    const { refetch } = useUserMeQuery();
    
    const [userDetails, setUserDetails] = useSetState({
        first_name: user?.first_name!,
        last_name: user?.last_name!,
        job_role: user?.job_role ?? null,
        industry: user?.industry!,
        region: user?.region ?? null,
        lines_of_business: user?.lines_of_business ?? [],
        role_division: user?.role_division ?? null
    })

    const createUpdateUserMutation = useUpdateUserDetailsMutation({
        onSuccess: () => refetch(),
    });

    const [invalidFields, setInvalidFields] = useState<string[]>([]);

    const dispatch = useDispatch()

    const validateInputs = () => {
        const invalidFields: string[] = []
        !userDetails.first_name.length && invalidFields.push("first_name")
        !userDetails.last_name.length && invalidFields.push("last_name")

        if (["Insurance", "Reinsurance"].includes(userDetails.industry!)) {
            !userDetails.role_division && invalidFields.push("role_division")
            !userDetails.lines_of_business.length && invalidFields.push("lines_of_business")
        }

        setInvalidFields(invalidFields)

        return invalidFields.length === 0
    }

    const handleUserUpdate = async () => {

        if (validateInputs()) {
            await createUpdateUserMutation.mutateAsync({
                ...userDetails,
                last_login_at: new Date().toISOString(),
            }); 
        } else {
            dispatch(
                setAlert({
                    type: "Error",
                    message: "Please fill in all the required fields."
                })
            )
        }

       
    };

    return (
        <div className={classes.ContentContainer}>
            <div className={cx(classes.Input, classes.DualColumnInputs)}>
                <TextInput
                    styles={{input: {border: invalidFields.includes("first_name") ? "1px solid var(--error-color)" : ""}}}
                    label={"First Name"}
                    value={userDetails.first_name}
                    variant={"twoColumn"}
                    onChange={(event) => {
                        setUserDetails({
                            first_name: event.currentTarget.value,
                        })
                    }}
                />
                <TextInput
                    styles={{input: {border: invalidFields.includes("last_name") ? "1px solid var(--error-color)" : ""}}}
                    label={"Last Name"}
                    value={userDetails.last_name}
                    variant={"twoColumn"}
                    onChange={(event) => {
                        setUserDetails({
                            last_name: event.currentTarget.value,
                        })
                    }}
                />
            </div>
            <div className={classes.Input}>
                <Select
                    label={"Industry"}
                    value={userDetails.industry}
                    variant={"primaryBg"}
                    data={INDUSTRIES}
                    onChange={(event) => {
                        if (!["Insurance", "Reinsurance"].includes(event!)) {
                            setUserDetails({
                                industry: event!,
                                lines_of_business: [],
                                role_division: null
                            })
                        } else {
                            setUserDetails({
                                industry: event!,
                            })
                        }
                        
                    }}
                />
            </div>
            {
                ["Insurance", "Reinsurance"].includes(userDetails.industry!) &&
                    <ControlSection
                        error={invalidFields.includes("lines_of_business")}
                        title="Lines of Business"
                        items={LINES_OF_BUSINESS}
                        selectedItems={
                            userDetails.lines_of_business
                        }
                        onIndicatorClick={
                            () => {
                                if (userDetails.lines_of_business.length !== 0) {
                                    setUserDetails({
                                        lines_of_business: []
                                    });
                                } else {
                                    setUserDetails({
                                        lines_of_business: LINES_OF_BUSINESS
                                    });
                                }
                            }
                        }
                        onChange={
                            (lines_of_business) => {
                                setUserDetails({
                                    lines_of_business: lines_of_business
                                });
                            }
                        }
                        cols={3}
                        width="100%"
                    />
            }
            
            <div className={cx(classes.Input, classes.DualColumnInputs)}>
                {
                    ["Insurance", "Reinsurance"].includes(userDetails.industry!) && 
                        <Select
                            styles={{input: {border: invalidFields.includes("role_division") ? "1px solid var(--error-color)" : ""}}}
                            label={"Role Division"}
                            data={ROLE_DIVISIONS}
                            value={userDetails.role_division}
                            variant={"twoColumn"}
                            onChange={(event) => {
                                setUserDetails({
                                    role_division: event!,
                                })
                            }}
                        />
                }
                
                <TextInput
                    label={"Job Title"}
                    value={userDetails.job_role ?? ""}
                    variant={"twoColumn"}
                    onChange={(event) => {
                        setUserDetails({
                            job_role: event.currentTarget.value,
                        })
                    }}
                />
            </div>
            <div className={classes.Input}>
                <Select
                    label={"Region You're Based In"}
                    value={userDetails.region}
                    variant={"primaryBg"}
                    data={NotificationRegions}
                    onChange={(event) => {
                        setUserDetails({
                            region: event!,
                        })
                    }}
                />
            </div>
            <div className={classes.ButtonContainer}>
                <Button 
                    onClick={() => {
                        handleUserUpdate()
                    }} 
                    size={{width: "20rem"}}
                >
                    Save Changes
                </Button>
            </div>
        </div>
    )
};

export default DetailsTab;