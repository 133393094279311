import React, { useEffect } from 'react'
import classes from "./Onboarding.module.css"
import { useHistory } from 'react-router-dom'
import { motion } from "framer-motion"
import Icon from '@mdi/react'
import { mdiArrowDownCircleOutline } from '@mdi/js'
import { Select, TextInput } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { getStoreAtNamespaceKey } from 'store/storeSelectors'
import { setAlert } from 'store/system/systemActions'
import { INDUSTRIES } from 'store/user/utils'
import { useLocalStorage } from '@mantine/hooks'
import LoadingScreen from 'components/_Library/LoadingScreen/LoadingScreen'

const NamesAndIndustry: React.FC = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const user = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user
    )

    const savedLocalStorage = localStorage.getItem("onboarded-user-details")

    const [updatedDetails, setUpdatedDetails] = useLocalStorage({
        key: "onboarded-user-details",
        defaultValue: savedLocalStorage ? JSON.parse(savedLocalStorage) : {
            first_name: user?.first_name ?? "",
            last_name: user?.last_name ?? "",
            industry: user?.industry ?? "Insurance",
            role_division: user?.role_division ?? null,
            region: user?.region ?? null,
            lines_of_business: user?.lines_of_business ?? [],
            job_role: user?.job_role ?? "",
        }
    })

    useEffect(() => {
        if (user) {
            setUpdatedDetails({
                first_name: user?.first_name ?? "",
                last_name: user?.last_name ?? "",
                industry: user?.industry ?? "Insurance",
                role_division: user?.role_division ?? null,
                region: user?.region ?? null,
                lines_of_business: user?.lines_of_business ?? [],
                job_role: user?.job_role ?? "",
            })
        }

        if (user?.industry) {
            history.push("/")
        }
    }, [user, history, setUpdatedDetails])

    const handleNextStep = () => {
        let invalidArray: string[] = []
        !updatedDetails.first_name.length && invalidArray.push("first_name")
        !updatedDetails.last_name.length && invalidArray.push("last_name")

        if (invalidArray.length === 0) {
            history.push(["Insurance", "Reinsurance"].includes(updatedDetails.industry) ? "/onboarding/2" : "/onboarding/3")
        } else {
            dispatch(
                setAlert({
                    type: "Error",
                    message: "Please fill in all the required fields."
                })
            )
        }
        
    }

    const displayForm = () => {
        return (
            <motion.div 
                className={classes.Container}
                initial={{
                    y: "100vh",
                    opacity: 0,
                }}
                animate={{
                    y: "0",
                    opacity: 1,
                    transitionDuration: "0.5s"
                }}
                exit={{
                    y: "-100vh",
                    opacity: 0,
                }}
            >
                <div className={classes.HeaderContainer}>
                    <div className={classes.Logo}></div>
                    <div className={classes.HeaderText}>
                        <h2 className={classes.Title}>Let's customise your experience in just a couple of steps.</h2>
                        <p className={classes.SubTitle}>It'll take just 30 seconds!</p>
                    </div> 
                </div>
                <div className={classes.FormContainer}>
                    <div className={classes.TwoColumnInput}>
                        <TextInput 
                            styles={{input: {border: !updatedDetails.first_name.length ? "1px solid var(--error-color)" : ""}}}
                            label="First Name*" 
                            variant='twoColumn' 
                            value={updatedDetails?.first_name}
                            onChange={(e) => {
                                setUpdatedDetails({
                                    ...updatedDetails,
                                    first_name: e.target.value
                                })
                            }}
                        />
                        <TextInput 
                            styles={{input: {border: !updatedDetails.last_name.length ? "1px solid var(--error-color)" : ""}}}
                            label="Last Name*" 
                            variant='twoColumn' 
                            value={updatedDetails?.last_name} 
                            onChange={(e) => {
                                setUpdatedDetails({
                                    ...updatedDetails,
                                    last_name: e.target.value
                                })
                            }}
                        />
                    </div>
                    <Select 
                        data={INDUSTRIES} 
                        value={updatedDetails?.industry} 
                        label="Industry*" 
                        variant="primaryBg" 
                        maxDropdownHeight={300}
                        onChange={(value) => {
                            setUpdatedDetails({
                                ...updatedDetails,
                                industry: value!
                            })
                        }}
                    />
                </div>
                <div 
                    className={classes.ContinueContainer}
                    onClick={handleNextStep}
                >
                    <Icon className={classes.ContinueIcons} path={mdiArrowDownCircleOutline} size={5}/>
                </div>
        
            </motion.div>
        )
    }

    return user ? displayForm() : <LoadingScreen/>
}

export default NamesAndIndustry