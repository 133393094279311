import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import classes from "./Onboarding.module.css"
import { motion } from "framer-motion"
import Button from 'components/_Library/Button/Button'
import { Select } from '@mantine/core'
import Icon from '@mdi/react'
import { mdiArrowUpCircleOutline } from '@mdi/js'
import { NotificationRegions } from 'store/system/systemTypes'
import { useUpdateUserDetailsMutation } from 'crud/hooks/users'
import { useLocalStorage } from '@mantine/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'store/user/userActions'
import { ExtendedAccessManagementUser } from 'types/auth'
import { RootState } from 'store/store'
import { getStoreAtNamespaceKey } from 'store/storeSelectors'

const Region: React.FC = () => {

    const history = useHistory()
    const savedLocalStorage = localStorage.getItem("onboarded-user-details")

    const dispatch = useDispatch();

    const user: ExtendedAccessManagementUser = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user!,
    );

    const [updatedDetails, setUpdatedDetails, removeUserDetails] = useLocalStorage({
        key: "onboarded-user-details",
        defaultValue: JSON.parse(savedLocalStorage!)
    })
    
    const createUpdateUserMutation = useUpdateUserDetailsMutation({
        onSuccess: () => {
            dispatch(
                setUser({
                    ...user,
                    ...updatedDetails
                })
            )
            history.push("/")
            removeUserDetails()
        },
    });

    const handleUserUpdate = async () => {
        await createUpdateUserMutation.mutateAsync({
            ...updatedDetails,
            last_login_at: new Date().toISOString(),
        });          
    };

    return (
        <motion.div 
            className={classes.Container}
            initial={{
                y: "50vh",
                opacity: 0,
            }}
            animate={{
                y: "0",
                opacity: 1,
                transitionDuration: "0.5s"
            }}
            exit={{
                y: "-50vh",
                opacity: 0,
            }}
        >
            <div className={classes.ContinueContainer}>
                <Link to="3">
                    <Icon className={classes.PreviousIcons} path={mdiArrowUpCircleOutline} size={2}/>
                </Link>   
            </div>

            <div className={classes.FormContainer}>
                <Select 
                    data={NotificationRegions} 
                    value={updatedDetails?.region} 
                    label="Region You're Based In (Optional)" 
                    variant={"primaryBg"} 
                    maxDropdownHeight={450}
                    onChange={(value) => setUpdatedDetails({...updatedDetails, region: value})}
                />
            </div>
            
            <div className={classes.ContinueContainer}>
                <Button 
                    type={"positive"} 
                    highlightBackground 
                    size={{width: "15rem"}}
                    onClick={() => {
                        handleUserUpdate()
                    }}
                >
                    Enter GEO
                </Button>
            </div>
        </motion.div>
    )
}

export default Region