import React from 'react'
import classes from "./Onboarding.module.css"
import { Link, useHistory } from 'react-router-dom'
import { motion } from "framer-motion"
import ControlSection from 'components/_Library/ControlSection/ControlSection'
import Icon from '@mdi/react'
import { mdiArrowDownCircleOutline, mdiArrowUpCircleOutline } from '@mdi/js'
import { useDispatch } from 'react-redux'
import { setAlert } from 'store/system/systemActions'
import { LINES_OF_BUSINESS } from 'store/user/utils'
import { useLocalStorage } from '@mantine/hooks'
import cx from "classnames"

const LinesOfBusiness: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const savedLocalStorage = localStorage.getItem("onboarded-user-details")

    const [updatedDetails, setUpdatedDetails] = useLocalStorage({
        key: "onboarded-user-details",
        defaultValue: JSON.parse(savedLocalStorage!)
    })

    const handleNextStep = () => {
        const invalidArray = []

        updatedDetails.lines_of_business.length === 0 && invalidArray.push("lines_of_business")

        if (invalidArray.length === 0) {
            history.push("/onboarding/3")
        } else {
            dispatch(
                setAlert({
                    type: "Error",
                    message: "Please select at least one Line of Business."
                })
            )
            
        }
        
    }

    return (
        <motion.div 
            className={classes.Container}
            initial={{
                y: "50vh",
                opacity: 0,
            }}
            animate={{
                y: "0",
                opacity: 1,
                transitionDuration: "0.5s"
            }}
            exit={{
                y: "-50vh",
                opacity: 0,
            }}
        >
            <div className={classes.ContinueContainer}>
                <Link to="1">
                    <Icon className={classes.PreviousIcons} path={mdiArrowUpCircleOutline} size={2}/>
                </Link>   
            </div>
             <div className={cx(classes.FormContainer, classes.ControlSectionContainer)}>
                <ControlSection
                    title="Lines of Business*"
                    items={LINES_OF_BUSINESS}
                    selectedItems={
                        updatedDetails!.lines_of_business ?? []
                    }
                    onIndicatorClick={
                        () => {
                            if (updatedDetails.lines_of_business.length !== 0) {
                                setUpdatedDetails({
                                    ...updatedDetails,
                                    lines_of_business: []
                                })
                            } else {
                                setUpdatedDetails({
                                    ...updatedDetails,
                                    lines_of_business: LINES_OF_BUSINESS
                                })
                            }
                        }
                    }
                    onChange={
                        (e) => {
                            setUpdatedDetails({
                                ...updatedDetails,
                                lines_of_business: e
                            })
                        }
                    }
                    cols={3}
                    width="100%"
                />
             </div>
            
             <div className={classes.ContinueContainer} onClick={handleNextStep}>
                <Icon className={classes.ContinueIcons} path={mdiArrowDownCircleOutline} size={5}/>  
            </div>
            
        </motion.div>
    )
}

export default LinesOfBusiness