import React from 'react'
import classes from "./Onboarding.module.css"
import { Link, useHistory } from 'react-router-dom'
import { motion } from "framer-motion"
import Icon from '@mdi/react'
import { mdiArrowDownCircleOutline, mdiArrowUpCircleOutline } from '@mdi/js'
import { Select, TextInput } from '@mantine/core'
import { setAlert } from 'store/system/systemActions'
import { ROLE_DIVISIONS } from 'store/user/utils'
import { useLocalStorage } from '@mantine/hooks'

const JobRoles: React.FC = () => {

    const history = useHistory()
    const savedLocalStorage = localStorage.getItem("onboarded-user-details")

    const [updatedDetails, setUpdatedDetails] = useLocalStorage({
        key: "onboarded-user-details",
        defaultValue: JSON.parse(savedLocalStorage!)
    })

    const handleNextStep = () => {
        const invalidArray = []

        if (updatedDetails.role_division === null && ["Insurance", "Reinsurance"].includes(updatedDetails.industry!)) {
            invalidArray.push("role_division")
        }

        if (invalidArray.length === 0) {
            history.push("/onboarding/4")
        } else {
            setAlert({
                type: "Error",
                message: "Please fill in all the required fields."
            })
        }
    }

    return (
        <motion.div 
            className={classes.Container}
            initial={{
                y: "50vh",
                opacity: 0,
            }}
            animate={{
                y: "0",
                opacity: 1,
                transitionDuration: "0.5s"
            }}
            exit={{
                y: "-50vh",
                opacity: 0,
            }}
        >
            <div className={classes.ContinueContainer}>
                <Link to={["Insurance", "Reinsurance"].includes(updatedDetails.industry!) ? "2" : "1"}>
                    <Icon className={classes.PreviousIcons} path={mdiArrowUpCircleOutline} size={2}/>
                </Link>   
            </div>

            <div className={classes.FormContainer}>
                {
                    ["Insurance", "Reinsurance"].includes(updatedDetails.industry!) &&
                        <Select 
                        styles={{input: {border: updatedDetails.role_division === null ? "1px solid var(--error-color)" : ""}}}
                            label="Role Division*" 
                            value={updatedDetails?.role_division} 
                            data={ROLE_DIVISIONS} 
                            variant={"primaryBg"}
                            maxDropdownHeight={450}
                            onChange={(value) => {
                                setUpdatedDetails({
                                    ...updatedDetails,
                                    role_division: value,
                                })
                            }}
                        />
                }
                <TextInput 
                    label="Job Role (Optional)" 
                    value={updatedDetails?.job_role} 
                    variant={"primaryBg"}
                    onChange={(event) => {
                        setUpdatedDetails({
                            ...updatedDetails,
                            job_role: event.target.value,
                        })
                    }}
                />
            </div>
            
            <div className={classes.ContinueContainer} onClick={handleNextStep}>
                <Icon className={classes.ContinueIcons} path={mdiArrowDownCircleOutline} size={5}/>
            </div>
        </motion.div>
    )
}

export default JobRoles