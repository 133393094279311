import { SecureRoute } from "@okta/okta-react";
import React, { useEffect } from "react";
import {
  Switch, useHistory, useLocation, useRouteMatch,
} from "react-router-dom";
import NamesAndIndustry from "./NamesAndIndustry";
import LinesOfBusiness from "./LinesOfBusiness";
import JobRoles from "./JobRoles";
import Region from "./Region";
import Stepper from "components/_Library/Stepper/Stepper";
import { AnimatePresence } from "framer-motion"

export default function Onboarding() {

    const location = useLocation();
    const history = useHistory();

    const {path} = useRouteMatch();

    useEffect(() => {
        //If user falls on onboarding root page, push to first step
        location.pathname === "/onboarding" && history.push("/onboarding/1"); 
    }, [location, history]);

    const getActiveStep = () => {
        const path = location.pathname;
        const step = path.split("/")[2];
        return parseInt(step);
    }

    return (
        <>
            <Stepper
                numberOfSteps={4}
                activeStep={getActiveStep()}
            />
            <AnimatePresence>
                <Switch location={location} key={location.pathname}>
                    <SecureRoute
                        path={`${path}/1`}
                        render={() => {
                            return <NamesAndIndustry />
                        }}
                    />
                    <SecureRoute
                        path={`${path}/2`}
                        render={() => {
                            return <LinesOfBusiness />
                        }}
                    />
                    <SecureRoute
                        path={`${path}/3`}
                        render={() => {
                            return <JobRoles />
                        }}
                    />
                    <SecureRoute
                        path={`${path}/4`}
                        render={() => {
                            return <Region />
                        }}
                    />
                </Switch>
            </AnimatePresence>
        </>
        
    );
}
